.select-as-semantic {
    position: relative;

    .remove-select-button {
        position: absolute;
        background: white !important;
        right: 1px;
        top: 1px;
        bottom: 1px;
        margin: 0 !important;
        padding: 8px !important;
    }

    select {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
        margin: 0 !important;
        outline: 0 !important;
        -webkit-appearance: none !important;
        tap-highlight-color: rgba(255, 255, 255, 0) !important;
        line-height: 1.21428571em !important;
        padding: 0.67857143em 1em !important;
        font-size: 1em !important;
        background: #fff !important;
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
        color: rgba(0, 0, 0, 0.87) !important;
        border-radius: 0.28571429rem !important;
        -webkit-box-shadow: 0 0 0 0 transparent inset !important;
        box-shadow: 0 0 0 0 transparent inset !important;
        -webkit-transition: color 0.1s ease, border-color 0.1s ease !important;
        transition: color 0.1s ease, border-color 0.1s ease !important;
    }
}

.selected_dates {
    display: flex;

    &_text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
    }

    &_color {
        position: relative;

        &:before {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: #db2828;
            transform: rotate(45deg);
        }

        &:after {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: #db2828;
            transform: rotate(-45deg);
        }
    }
}

.ui.dropdown .menu > .item > .description,
.ui.dropdown > .text > .description,
.ui.dropdown > .text {
    overflow: hidden !important;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.form .field > label {
    color: #4d4d4d !important;
    font-weight: 500 !important;
}

.label-disabled {
    color: #999 !important;
}

.react-datepicker-popper {
    z-index: 5 !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker__close-icon {
    &:after {
        padding: 0 !important;
        color: black !important;
        background: transparent !important;
        font-size: 17px !important;
        font-weight: bold !important;
    }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
   background-color: #18A8CC!important;
}

.input-error {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ui.input.error > input {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
    -webkit-box-shadow: none;
    box-shadow: none !important;
}

.label-error {
    color: #9f3a38 !important;
    font-size: 0.8rem;
}

.bool-radio-button {
    display: flex;
    height: 38px;
    align-items: center;

    .ui.radio.checkbox {
        padding-right: 15px;
        white-space: nowrap!important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form-select {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    button {
        margin-left: 8px !important;
    }

    /* &_disabled {
         opacity: .8;
         background: rgba(1, 1, 1, 0.05);
     }*/
}

.ui.input:hover,
.react-datepicker-wrapper:hover {
    input {
        border-color: rgba(34, 36, 38, 0.35) !important;
        box-shadow: none;
    }
}

.ui.form textarea:hover {
    border-color: rgba(34, 36, 38, 0.35) !important;
    box-shadow: none;
}

.custom-checkbox {
    position: relative;
    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &_disabled {
        opacity: 0.4;
    }
}

input,
textarea {
    border: none !important;
    box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.15), 0 0 0 2px transparent !important;

    &:focus,
    .ui.selection.active.dropdown {
        box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    }

    &:hover:not(:focus) {
        box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.35), 0 0 0 2px transparent !important;
    }
}

.ui.selection.active.dropdown {
    border: none;
    box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
}

.ui.selection.active.dropdown .menu {
    box-shadow: none;
    border-color: rgba(34, 36, 38, 0.15) !important;
}

.return_cost_field {
    .row {
        align-items: flex-end!important;
    }
}

.select_grid {
    max-height: 250px;
    overflow-y: auto;
}

.field-file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_link {
        color: #18A8CC;
        font-weight: 500;
        width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
            cursor: pointer;
        }

        &-error {
            color: #9f3a38;
        }
    }

    &_error {
        background-color: #fff6f6;
        border-radius: 4px;
        border: 1px solid #e0b4b4;
        padding-left: 0.25rem;
    }
}
