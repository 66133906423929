.card-header-panel {
    position: fixed;
    z-index: 10;
    top: 40px;
    width: 100%;
    height: 56px;
    padding: 12px 32px;
    display: flex;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    justify-content: space-between;

    &_title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        button {
            margin-right: 12px !important;
            background: white !important;
        }
    }
}

.card-actions-panel {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 12;
    min-height: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    div {
        width: 1100px;
        display: flex;
        justify-content: flex-end;
    }
}

.card-content {
    width: 100%;
    padding: 120px 0 94px 0;
    display: flex;
    justify-content: center;
    position: relative;

    > div {
        display: flex;
    }

    .ui.secondary.pointing.menu {
        position: sticky;
        top: 120px;
        background-color: #f9f9f9 !important;
        height: max-content;
        .item {
            color: #4d4d4d !important;
        }
    }

    &-block {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        box-sizing: border-box;
        min-width: 620px;
        position: relative;
        width: 1100px;
        display: flex;
        margin: 0 32px;
        flex-direction: column;

        &__full {
            position: fixed !important;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 900;
            margin: auto;
            overflow: hidden;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &_menu {
            flex-direction: column;
        }

        &_loader {
            top: calc(50vh) !important;
        }

        &_header {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            padding: 15px 24px 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            position: sticky;
            left: 0;
            top: 0;

            &_title {
                display: flex;
                align-items: center;

                &__ext {
                    font-size: 14px;
                    padding-left: 12px;
                    color: #999 !important;
                }
            }

            &_actions {
                display: flex;
                align-items: center;
                button {
                    background: transparent!important;

                    &:hover {
                        background: #e0e1e2 none!important;
                    }
                }
            }


            &_accordion {
                &:hover:not([disabled]) {
                    cursor: pointer;
                }
            }

            &__disabled {
                color: #999 !important;
            }
        }

        &_open {
            display: block;
            position: relative;
            /*overflow: auto;*/
        }

        &_full {
            display: block;
            position: relative;
            overflow: auto;
            max-height: calc(100vh - 80px);
        }

        &_close {
            display: none;
        }
    }
}
