.scroll-grid-container {
    display: grid;
    overflow: auto;
    height: calc(100vh - 150px);
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    grid-template-rows: auto 76px;
    background-color: white;
    border-radius: 0 0 5px 5px;
}

.noscroll {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    min-height: calc(100vh - 150px);
    display: grid;
    grid-template-rows: auto 76px;
    background-color: white;
    border-radius: 0 0 5px 5px;
}

.root-scroll {
    box-sizing: border-box;
    min-height: calc(100vh - 150px);
    /* flexbox */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

tbody {
    color: #4d4d4d !important;
}

/*tbody, thead tr {
    display: table!important;
    width: 100%!important;
    table-layout: fixed!important;
}*/

.grid_small {
    height: calc(100vh - 420px);
}

.grid-table {
    position: relative;
    border-collapse: collapse;
    border: none !important;

    &_fixed-header {
        position: relative;
        z-index: 20;
        top: 121px;
    }
}

.active.content {
    display: table-row !important;
}

/*.ui.grid {
    margin-bottom: 1px;
}*/

.ui.table thead th {
    background-color: white !important;
}

.grid-action-btn {
    white-space: nowrap;
}

/*.ui.table {
    thead {
        th {
            border-bottom: none !important;
        }
    }
}*/

.sticky-header {
    border-top: none !important;

    th {
        /*min-width: 100px;
        max-width: 100px;*/
        position: sticky !important;
        position: -webkit-sticky !important;
        top: 0;
        z-index: 5;
        border-bottom: 1px solid #ddd !important;
        font-size: 12.5px !important;
        background-attachment: local !important;
        word-break: break-word;
    }

    .actions-column {
        right: 0;
        min-width: 150px;
        width: 150px;
    }

    .actions-column:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        width: 15px;
        background: linear-gradient(
                to right,
                rgba(249, 250, 251, 0) 0%,
                rgba(249, 250, 251, 1) 100%
        );
    }

    .small-column {
        z-index: 8 !important;
    }

    th:not(.small-column) {
        padding-left: 0 !important;

        .input {
            width: 100%;

            input[type='text'] {
                padding-left: 10px !important;
                padding-right: 5px !important;
            }
        }
    }
}

.sticky-header th:not(.small-column) .input input,
.sticky-header th:not(.small-column) .input .label {
    border-color: #ccc;
}

.grid-header-panel {
    margin: 0 !important;
    border: 1px solid #e6e6e6;
    background-color: white;
    width: 100%;

    &__noscroll {
        position: sticky;
        top: 55px;
        z-index: 10;

        &:before {
            content: '';
            position: fixed;
            z-index: 10;
            top: 40px;
            height: 15px;
            width: 100%;
            left: 0;
            background: #f9f9f9;
        }
    }

    .create-button {
        height: 33px;
        min-width: 150px;
        white-space: nowrap;
        margin-right: 10px !important;
        font-size: unset !important;
    }

    .grid-right-elements {
        display: flex !important;
        justify-content: flex-end;

        .orange {
            opacity: 0.7;
        }
    }

    .representation {
        display: flex;
        align-items: center;
        flex-grow: 1;

        label {
            font-weight: 500;
            padding-right: 8px;
            color: #4d4d4d !important;
        }

        .field {
            margin: 0 .25em 0 0;
            flex-grow: 1;
        }
    }
}

.status-value {
    white-space: nowrap;
    width: 100%;
}

.records-counter {
    white-space: nowrap;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #4d4d4d !important;
}

.grid-row > td > div {
    max-height: 150px;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    width: max-content;
    min-width: inherit;
}

.grid-row td,
.grid-row th {
    /*min-width: 100px;
    max-width: 100px;
    box-sizing: content-box;
    border-top: 1px solid #ddd !important;*/
    background-color: white;
}

.grid-row-autogrouping:hover {
    cursor: pointer;
}


.link-cell {
    color: #18a8cc !important;

    width: 100%;

    &:hover {
        text-decoration-line: underline;
        cursor: pointer;
    }
}

.dropdown-blue {
    color: #18a8cc !important;
}

.grid-row {
    /*.address-cell {
        max-width: 100px;

        .cell-grid {
            width: inherit;
        }
    }*/

    &-selected td {
        background-color: #e3f5f9 !important;
    }

    &-active td {
        // background-color: #f9f9f9!important;
    }

    &-marker {
        font-weight: 600 !important;
    }

    .dragging-cell {
        background-color: #e3f5f9 !important;
        box-sizing: border-box;
    }

    &-red td {
        background-color: #ffd5d1 !important;
    }
}

.column-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    display: block;
    position: relative;
}

.grid-row .checkbox,
.grid-row .checkbox input.hidden + label {
    cursor: default;
}

.grid-row:hover td {
    background-color: #f0f0f0 !important;
}

.grid-row th {
    min-width: 150px;
    width: 150px;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    right: 0;
    background: #f9fafb;
    padding: 5px 15px;
    border-left: 1px solid #ddd !important;
}

.grid-row th button {
    margin: 0 0 3px 0 !important;
    width: 100%;
}

.grid-row .ui.fitted.checkbox {
    margin-top: 4.5px;
}

.grid-footer {
    position: sticky;
    bottom: 0;
}

.grid-footer-panel {
    background-color: white;
    /*white-space: nowrap;*/
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 15px 15px 0 15px !important;
    min-width: 1260px;
    width: calc(100vw - 28px);
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    flex-wrap: nowrap !important;
    height: max-content;
    z-index: 15;
    min-height: 62px;
    flex-direction: column !important;

    &-dictionary {
        width: 100% !important;
        min-width: 100% !important;

        >div {
            position: sticky;
            left: 24px;
            width: max-content!important;
        }
    }

    &_autogrouping {
        flex-direction: row !important;
        align-items: flex-end !important;
        position: fixed;
        bottom: 30px;
        left: 30px;
        z-index: 10;
        max-width: calc(100vw - 29px);
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        //border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        &:after {
            content: '';
            position: fixed;
            z-index: 10;
            bottom: 0;
            left: 0;
            height: 16px;
            width: 100%;
            background: #f9f9f9;
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        width: 100%;
        white-space: nowrap;

        :first-child {
            display: flex;
            padding-bottom: 3px;

            > div {
                display: flex;
                width: 33.3%;
            }
        }
    }

    .row {
        padding-top: 0 !important;
        flex-wrap: nowrap !important;
    }

    .grid-right-elements {
        text-align: right;
    }

    .grid-right-elements label {
        font-size: 13px;
    }

    .footer_actions {
        display: flex;
        flex-wrap: wrap;

        button {
            margin-bottom: 8px;
        }

        &_button {
            background-color: transparent !important;
            color: #4d4d4d !important;
            font-weight: 500;
            text-transform: uppercase !important;

            &:hover {
                background-color: rgba(0, 0, 0, 0.06) !important;
            }
        }
    }

    .summary-content-block_footer_accordion {
        margin-right: 4px;
        cursor: pointer;
    }

    .column {
    }
}

.grid-marker {
    position: absolute;
    left: 0;
    height: 100%;
    width: 7px !important;
    top: 0;
    background-color: #18A8CC;
    z-index: 8;
}

.filter-popup > div {
    text-align: center;
    margin-bottom: 10px !important;
}

.from-popup {
    filter: none !important;
    min-width: 250px !important;
    max-width: 100% !important;
    position: absolute !important;

    .separator {
        display: flex;
        align-items: center;
    }
}

.label-margin {
    margin: 3px !important;
}

.cell-edit {
    white-space: nowrap;
    width: 20%;

    .button {
        margin-left: 8px;
    }
}

$orange: #fcd8c1;
$olive: #ebf1c0;
$teal: #c5f9f6;
$pink: #fbeff2;
$blue: #e3f5f9;
$red: #f1c2c3;
$yellow: #fff2c6;
$green: #d7e8dd;
$violet: #e9dff3;
$purple: #f3def5;
$brown: #ffc8ab;
$grey: #f0f0f0;

.color-info {
    &-orange {
        background-color: $orange !important;
    }

    &-olive {
        background-color: $olive !important;
    }

    &-teal {
        background-color: $teal !important;
    }
}

.grid-row {
    &.olive td {
        background-color: $olive;
    }

    &.orange td {
        background-color: $orange;
    }

    &.teal td {
        background-color: $teal;
    }

    &.pink td {
        background-color: $pink;
    }
}

.color_cell {
    &_olive {
        background-color: $olive !important;
    }

    &_orange {
        background-color: $orange !important;
    }

    &_teal {
        background-color: $teal !important;
    }

    &_pink {
        background-color: $pink !important;
    }

    &_blue {
        background-color: $blue !important;
    }

    &_red {
        background-color: $red !important;
    }

    &_yellow {
        background-color: $yellow !important;
    }

    &_green {
        background-color: $green !important;
    }

    &_violet {
        background-color: $violet !important;
    }

    &_purple {
        background-color: $purple !important;
    }

    &_brown {
        background-color: $brown !important;
    }

    &_grey {
        background-color: $grey !important;
    }
}

.react-resizable {
    &:hover .react-resizable-handle-se {
        opacity: 1 !important;
    }
}

.react-resizable-handle-se {
    top: 0;
    /*right: -8px!important;*/
    /* width: 16px!important;*/
    cursor: col-resize !important;
    height: 100% !important;
    z-index: 100 !important;
    position: absolute !important;
    user-select: none !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    opacity: 0;

    &:active {
        &:before {
            content: '';
            top: 2px;
            height: calc(100% - 4px);
            background-color: #ddd;
            /*left: 8px;*/
        }

        &:after {
            opacity: 0;
        }
    }

    &:before {
        content: '';
        top: 25%;
        width: 1px;
        height: 50%;
        position: absolute;
        transition: all linear 100ms;
        background-color: #ddd;
        left: 7px;
    }

    &:after {
        content: '';
        top: 25%;
        width: 1px;
        height: 50%;
        position: absolute;
        transition: all linear 100ms;
        background-color: #ddd;
        left: 9px;
    }
}

.react-resizable-handle {
    position: absolute;
    /* width: 10px !important;
     height: 100% !important;*/
    background-image: none !important;
    /* background-repeat: no-repeat; */
    /* background-origin: content-box; */
    /* box-sizing: border-box; */
    /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…BMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+); */
    /* background-position: bottom right; */
    /* padding: 0 3px 3px 0; */
}

.footer-info {
    display: flex;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    color: #4d4d4d !important;

    &-value {
        font-weight: 500;
        padding-left: 8px;
    }

    &-close {
        position: absolute;
        top: 0;
        right: -20px;

        &:hover {
            cursor: pointer;
        }
    }

    div:not(:last-child) {
        padding-right: 24px;
    }
}

.footer-info-label {
    display: flex;
    font-weight: bold;
    color: #18a8cc;
    align-items: baseline;
    padding-bottom: 8px;

    &:hover {
        cursor: pointer;
    }
}

.ellipsis-actions-btn {
    height: 23.94px;

    i {
        vertical-align: text-top;
    }
}

.grid-mass-updates {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;

    .ui.selection.dropdown {
        white-space: nowrap;
    }

    .ui.grid {
        width: 100%;
        flex-wrap: nowrap !important;
    }

    .ui.grid > .row {
        flex-wrap: nowrap;
    }

    label {
        padding-right: 8px;
        margin: 0 !important;
        font-weight: 500 !important;
        color: #4d4d4d !important;
    }

    &-fields {
        display: inline-flex !important;
        align-items: center;

        .field {
            margin: 0 !important;
            width: 100%;
        }
    }

    &-save {
        margin-left: 8px !important;
        height: 38px !important;
    }

    //&-value {
    //    .field {
    //        margin: 0 !important;
    //        padding-left: 8px;
    //    }
    //    display: flex;
    //    align-items: center;
    //}
}

.value-cell {
    overflow: inherit !important;
}

.cell-grid {
    display: flex;
    position: relative;
    width: 100% !important;
    

    &:hover .cell-grid-copy-btn, &:hover .cell-grid-info-btn {
        -webkit-transition: opacity 0.8s ease-in-out;
        -moz-transition: opacity 0.8s ease-in-out;
        -o-transition: opacity 0.8s ease-in-out;
        transition: opacity 0.8s ease-in-out;
        opacity: 1;
    }

    &-value {
        position: relative;
        width: min-content;
        min-width: 10px;
        min-height: 18px;
        display: flex;

        &:hover {
            cursor: pointer;
        }

        &_empty {
            width: 50%;
        }
    }

    &-form {
        width: calc(100% - 15px);
        min-width: 10px;
        min-height: 18px;
        display: flex;
        z-index: 1;

        > .form {
            width: inherit !important;

            .dropdown.icon.clear {
                cursor: pointer;
                position: absolute;
                width: auto;
                height: auto;
                line-height: 1.21428571em;
                right: 1em;
                z-index: 3;
                margin: -.78571429em;
                padding: .91666667em;
                opacity: .8;
                transition: opacity .1s ease;
            }

            .react-datepicker-popper {
                width: max-content !important;
            }

            .react-datepicker__input-container {
                min-width: 50px;
                min-height: 18px;

                > div {
                    height: 18px;
                }
            }

            .text {
                width: calc(100% - 25px);
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .date_grid {
                border: none !important;
                box-shadow: none !important;
                background: transparent !important;
                width: calc(100% - 25px) !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .textarea {
                position: absolute;
                background: white !important;
                z-index: 3;
                width: 100%;
                padding: 8px;
                top: -8px;
                box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;

                &:focus {
                    outline: none !important;
                }
            }

            .bool-radio-button {
                position: absolute;
                z-index: 3;
                background: white;
                top: -10px;
                padding: 8px !important;
                box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
            }

            .input {
                position: absolute;
                z-index: 3;
                background: white;
                padding: 8px;
                top: -8px;
                box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
            }

            .react-datepicker-wrapper {
                position: absolute;
                top: -8px;
                width: inherit;
                background: white;
                z-index: 8;
            }
        }

        .state-field {
            position: absolute !important;
            z-index: 3;
            top: -8px;
            background: white;
            width: auto;
            border-color: transparent !important;
            padding: 0 !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            .ui.selection.dropdown .menu {
                border-top-width: 1px!important;
            }
            .menu {
               width: inherit!important;
                > .item {
                    white-space: nowrap!important;
                }
            }

            input {
                top: -8px;
            }
            >.text {
                cursor: text;
                position: relative;
                left: 16px!important;
                z-index: 3;
                top: 10px;
            }
        }

        .ui.selection.active.dropdown {
            box-shadow: none;
        }
    }

    &-copy-btn {
        opacity: 0;
        padding: 0 8px;
        color: rgba(34, 36, 38, 0.15);

        :hover {
            cursor: pointer;
            color: rgba(34, 36, 38, 0.35);
        }
    }

    &-info-btn {
        opacity: 0;
        color: rgba(34, 36, 38, 0.15);

        :hover {
            cursor: pointer;
            color: rgba(34, 36, 38, 0.35);
        }
    }

    .ui.loader {
        position: relative !important;
        padding: 0 8px;
    }
}

.value-cell {
    padding-left: 0 !important;
}

.all-filters-popup {
    min-width: 250px;
    max-width: 100%;
}

.all-filters-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 12px;
}

.hidden-header {
    height: 0 !important;

    th {
        height: 0 !important;
        /*padding-top: 0!important;
        padding-bottom: 0!important;*/
        /*border: none!important;*/
    }
}
